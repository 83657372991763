import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw'

export default function ProductBanner({left, content, header, image}){
    const BannerContent = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 50px 50px;
        background: linear-gradient(287.58deg, #A1C654 20.01%, #B0E640 91.64%);
        max-height: 60vh;
        h3{
            color: white;
            font-family: Oswald;
            font-size: 4rem;
        }
        p{
            text-align: left;
            color: white;
        }
        @media (max-width: 900px){
            p{
                text-align: center;
            }
            h3{
                text-align: center;
            }
            max-height: 100%;
        }
    `;

    const SingleBanner = styled.div`
        
        max-width: 1500px;
        margin: 25px auto;
        margin-bottom: 25px;
        background-color: white;

        .halftile{
            display: flex;
            justify-content: center;
            align-items: center;
        }

        img{
            max-height: 60vh;
            width: auto;
        }
    `;

return(
    <SingleBanner className="row">
        <div className={ (left==true) 
                            ? "col-lg-6 col-12 p-0 order-lg-2 order-1 halftile"
                            : "col-lg-6 col-12 p-0 order-lg-2 order-1 halftile"
                        }>
            <img
                src={image}
                className="banner-image" 
            />
        </div>
        <div className={ (left==true) 
                            ? "col-lg-6 col-12 p-0 order-lg-1 order-1"
                            : "col-lg-6 col-12 p-0 order-lg-2 order-1"
                        }>
            <BannerContent>
                <div>
                    <h3>{header}</h3>
                    <ReactMarkdown rehypePlugins={[rehypeRaw]} children={content} />
                </div>
            </BannerContent>
        </div>
    </SingleBanner>
);
}