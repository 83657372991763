import React from 'react';
import { graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import HeroTypeD from '../components/Heroes/HeroTypeD';
import BannerInfoTypeC from '../components/Banners/BannerInfoTypeC';
import ProductBanner from '../components/Banners/ProductBanner';
import BannerTypeA2 from '../components/Banners/BannerTypeA2';
import SEO from '../components/SEO/SEO';

export default function CategoryPage({ data }) {
  const hero = data.strapiProducts.Hero_Product;
  const bottom_info_banner = data.strapiProducts.Bottom_Info_Banner;
  const content_array = data.strapiProducts.DynoContent;
  return (
    <>
      <SEO
        title={hero.Title}
        description={String(hero.Content).substring(0, 30)}
      />
      <HeroTypeD
        title={hero.Title}
        content={hero.Content}
        heroImage={
          hero.Background_Image.localFile.childImageSharp.gatsbyImageData
        }
        buttonLabel={hero.Button.Label}
        buttonLink={hero.Button.Custom_Link_Slug}
      />
      <div className="container-fluid">
        {content_array.map((block, index) =>
          block.strapi_component == 'product-banner.product-banner' ? (
            <ProductBanner
              content={block.Content}
              header={block.Title}
              image={block.Image.url}
              left={block.LeftORRight}
              key={index}
            />
          ) : (
            <BannerTypeA2
              title={block.Article_Link.Title}
              description={block.Article_Link.Preview_Text}
              bannerImage={block.Article_Link.BannerImage.url}
              link={block.Article_Link.slug}
              buttonLabel="READ ARTICLE"
              key={index}
            />
          )
        )}
        )}
      </div>
      <BannerInfoTypeC
        center
        description={bottom_info_banner.Content}
        bgImage={
          bottom_info_banner.Image.localFile.childImageSharp.gatsbyImageData
        }
      />
    </>
  );
}

export const query = graphql`
  query CategoryPageQuery($slug: String!) {
    strapiProducts(slug: { eq: $slug }) {
      slug
      DynoContent
      Bottom_Info_Banner {
        Content
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        Button {
          Custom_Link_Slug
          Label
        }
      }
      Hero_Product {
        Content
        Title
        Button {
          Custom_Link_Slug
          Label
        }
        Background_Image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
