import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby'
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from 'gatsby-background-image';

import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw'

const MOBILE_BREAKPOINT = "768px";

const Wrapper = styled.div`
    .bg-image{
        background: linear-gradient(to right, rgba(0, 0, 0, 0.75) 50%, rgba(0, 0, 0, 0.5) 100%);
    }
`

const Container = styled.div`
    width: 50%;
    padding: 80px 50px;
    padding-right: 0px;
    font-style: normal;
    color: #FFFFFF;

    .hoc-button-outline {
        display: inline-block;
        font-size: 16px;
    }

    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        width: 100%;
        padding: 70px 20px;
        text-align: center;
    }
`;

const Title = styled.h1`
    margin: 0px 0 0px;
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 84px;
    line-height: 130%;
    text-transform: uppercase;
    text-align: left;
    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        margin: 70px 0 30px;
        font-size: 36px;
        text-align: center;
    }
`;

const SubTitle = styled.h2`
    color: var(--darkgreen);
    font-family: 'Oswald';
    text-align: center;
`;


const Description = styled.div`
    margin-bottom: 80px;
    font-family: 'Lato';
    font-weight: 300;
    font-size: 20px;
    line-height: 120%;
    opacity: 0.7;
    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        margin-bottom: 60px;
    }
`;

export default function HeroTypeD({ title, subtitle, content, heroImage, buttonLabel, buttonLink }){
    const image = getImage(heroImage)
    const bgImage = convertToBgImage(image)

    return(
        <Wrapper>
        <BackgroundImage
                Tag="div"
                {...bgImage}
                preserveStackingContext
                className="bg-image"
                style={{
                    width: '100%',
                    minHeight: '80vh',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    position: 'relative',
                }}
            >
                <Container>
                    <Title>{title}</Title>
                    <SubTitle>{subtitle}</SubTitle>
                    <Description>
                        <ReactMarkdown rehypePlugins={[rehypeRaw]} children={content} />
                    </Description>
                    <Link to={buttonLink}>
                        <button className="hoc-button-outline">{buttonLabel}</button>
                    </Link>
                </Container>
            </BackgroundImage>
        </Wrapper>
    );
}