import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';

import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw'


const MOBILE_BREAKPOINT = '992px'

const Wrapper = styled.div`
    margin: 70px 20px;
    
    .bg-image,
    .bg-image::after,
    .bg-image::before{
        background-size: cover;
        height: 58vh;
        background-size: cover;
        @media (max-width: ${MOBILE_BREAKPOINT}){
            width: 100%;
            height: 100%;
            min-height: 105vh;
            background-position: center;
        }
    }
`;


const Container = styled.div`
    padding: 47px 80px;
    text-align: ${props => props.center ? 'center' : 'left'};
    align-items: center;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;


    .hoc-button {
        margin-top: 70px;
        display: inline-block;
        padding: 20px 3rem;
    }
    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        min-height: 420px;
        padding: ${props => props.center ? '50px 40px 60px' : '30px 100px 140px 30px'};
    }
`;

const Description = styled.div`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: white;
    opacity: 1;
    text-align: center;

    max-width: 1100px;
    margin: 0px auto;

    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        font-size: 15px;
        width: 100%;
        h2{
            line-height: 39px;
        }
        h3{
            line-height: 25px;
        }
    }
    h2{
        color: white;
        opacity: 1;
        text-align: center;
    }
    h3{
        text-align: center;
        font-family: Oswald;
        padding: 15px 5px;
    }
`;


export default function BannerInfoTypeB({description, bgImage , buttonLabel, buttonLink, center}){
    
    let b_label = 'SHOP ONLINE';
    if(buttonLabel){
        b_label = buttonLabel
    }
    else{
        b_label = 'SHOP ONLINE';
    }
    
    return(
        <Wrapper>
            <BgImage
                image={bgImage}
                preserveStackingContext
                className="bg-image"
                style={{
                    width: '',
                    height: '',
                    backgroundPosition: 'center',
                    borderRadius: '3px',
                    overflow: 'hidden',
                    backgroundSize: '',
                }}
            >
                <Container center={center}>
                    <Description center={center}>
                        <ReactMarkdown rehypePlugins={[rehypeRaw]} children={description} />
                    </Description>
                    <Link to={buttonLink}className="hoc-button">{b_label}</Link>
                </Container>
            </BgImage>
        </Wrapper>
    );
}